import { SENSITIVE_STRING } from "@smithy/smithy-client";
import { CognitoIdentityProviderServiceException as __BaseException } from "./CognitoIdentityProviderServiceException";
export const RecoveryOptionNameType = {
    ADMIN_ONLY: "admin_only",
    VERIFIED_EMAIL: "verified_email",
    VERIFIED_PHONE_NUMBER: "verified_phone_number",
};
export const AccountTakeoverEventActionType = {
    BLOCK: "BLOCK",
    MFA_IF_CONFIGURED: "MFA_IF_CONFIGURED",
    MFA_REQUIRED: "MFA_REQUIRED",
    NO_ACTION: "NO_ACTION",
};
export const AttributeDataType = {
    BOOLEAN: "Boolean",
    DATETIME: "DateTime",
    NUMBER: "Number",
    STRING: "String",
};
export class InternalErrorException extends __BaseException {
    constructor(opts) {
        super({
            name: "InternalErrorException",
            $fault: "server",
            ...opts,
        });
        this.name = "InternalErrorException";
        this.$fault = "server";
        Object.setPrototypeOf(this, InternalErrorException.prototype);
    }
}
export class InvalidParameterException extends __BaseException {
    constructor(opts) {
        super({
            name: "InvalidParameterException",
            $fault: "client",
            ...opts,
        });
        this.name = "InvalidParameterException";
        this.$fault = "client";
        Object.setPrototypeOf(this, InvalidParameterException.prototype);
    }
}
export class NotAuthorizedException extends __BaseException {
    constructor(opts) {
        super({
            name: "NotAuthorizedException",
            $fault: "client",
            ...opts,
        });
        this.name = "NotAuthorizedException";
        this.$fault = "client";
        Object.setPrototypeOf(this, NotAuthorizedException.prototype);
    }
}
export class ResourceNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "ResourceNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "ResourceNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ResourceNotFoundException.prototype);
    }
}
export class TooManyRequestsException extends __BaseException {
    constructor(opts) {
        super({
            name: "TooManyRequestsException",
            $fault: "client",
            ...opts,
        });
        this.name = "TooManyRequestsException";
        this.$fault = "client";
        Object.setPrototypeOf(this, TooManyRequestsException.prototype);
    }
}
export class UserImportInProgressException extends __BaseException {
    constructor(opts) {
        super({
            name: "UserImportInProgressException",
            $fault: "client",
            ...opts,
        });
        this.name = "UserImportInProgressException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UserImportInProgressException.prototype);
    }
}
export class UserNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "UserNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "UserNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UserNotFoundException.prototype);
    }
}
export class InvalidLambdaResponseException extends __BaseException {
    constructor(opts) {
        super({
            name: "InvalidLambdaResponseException",
            $fault: "client",
            ...opts,
        });
        this.name = "InvalidLambdaResponseException";
        this.$fault = "client";
        Object.setPrototypeOf(this, InvalidLambdaResponseException.prototype);
    }
}
export class LimitExceededException extends __BaseException {
    constructor(opts) {
        super({
            name: "LimitExceededException",
            $fault: "client",
            ...opts,
        });
        this.name = "LimitExceededException";
        this.$fault = "client";
        Object.setPrototypeOf(this, LimitExceededException.prototype);
    }
}
export class TooManyFailedAttemptsException extends __BaseException {
    constructor(opts) {
        super({
            name: "TooManyFailedAttemptsException",
            $fault: "client",
            ...opts,
        });
        this.name = "TooManyFailedAttemptsException";
        this.$fault = "client";
        Object.setPrototypeOf(this, TooManyFailedAttemptsException.prototype);
    }
}
export class UnexpectedLambdaException extends __BaseException {
    constructor(opts) {
        super({
            name: "UnexpectedLambdaException",
            $fault: "client",
            ...opts,
        });
        this.name = "UnexpectedLambdaException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UnexpectedLambdaException.prototype);
    }
}
export class UserLambdaValidationException extends __BaseException {
    constructor(opts) {
        super({
            name: "UserLambdaValidationException",
            $fault: "client",
            ...opts,
        });
        this.name = "UserLambdaValidationException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UserLambdaValidationException.prototype);
    }
}
export const DeliveryMediumType = {
    EMAIL: "EMAIL",
    SMS: "SMS",
};
export const MessageActionType = {
    RESEND: "RESEND",
    SUPPRESS: "SUPPRESS",
};
export const UserStatusType = {
    ARCHIVED: "ARCHIVED",
    COMPROMISED: "COMPROMISED",
    CONFIRMED: "CONFIRMED",
    EXTERNAL_PROVIDER: "EXTERNAL_PROVIDER",
    FORCE_CHANGE_PASSWORD: "FORCE_CHANGE_PASSWORD",
    RESET_REQUIRED: "RESET_REQUIRED",
    UNCONFIRMED: "UNCONFIRMED",
    UNKNOWN: "UNKNOWN",
};
export class CodeDeliveryFailureException extends __BaseException {
    constructor(opts) {
        super({
            name: "CodeDeliveryFailureException",
            $fault: "client",
            ...opts,
        });
        this.name = "CodeDeliveryFailureException";
        this.$fault = "client";
        Object.setPrototypeOf(this, CodeDeliveryFailureException.prototype);
    }
}
export class InvalidPasswordException extends __BaseException {
    constructor(opts) {
        super({
            name: "InvalidPasswordException",
            $fault: "client",
            ...opts,
        });
        this.name = "InvalidPasswordException";
        this.$fault = "client";
        Object.setPrototypeOf(this, InvalidPasswordException.prototype);
    }
}
export class InvalidSmsRoleAccessPolicyException extends __BaseException {
    constructor(opts) {
        super({
            name: "InvalidSmsRoleAccessPolicyException",
            $fault: "client",
            ...opts,
        });
        this.name = "InvalidSmsRoleAccessPolicyException";
        this.$fault = "client";
        Object.setPrototypeOf(this, InvalidSmsRoleAccessPolicyException.prototype);
    }
}
export class InvalidSmsRoleTrustRelationshipException extends __BaseException {
    constructor(opts) {
        super({
            name: "InvalidSmsRoleTrustRelationshipException",
            $fault: "client",
            ...opts,
        });
        this.name = "InvalidSmsRoleTrustRelationshipException";
        this.$fault = "client";
        Object.setPrototypeOf(this, InvalidSmsRoleTrustRelationshipException.prototype);
    }
}
export class PreconditionNotMetException extends __BaseException {
    constructor(opts) {
        super({
            name: "PreconditionNotMetException",
            $fault: "client",
            ...opts,
        });
        this.name = "PreconditionNotMetException";
        this.$fault = "client";
        Object.setPrototypeOf(this, PreconditionNotMetException.prototype);
    }
}
export class UnsupportedUserStateException extends __BaseException {
    constructor(opts) {
        super({
            name: "UnsupportedUserStateException",
            $fault: "client",
            ...opts,
        });
        this.name = "UnsupportedUserStateException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UnsupportedUserStateException.prototype);
    }
}
export class UsernameExistsException extends __BaseException {
    constructor(opts) {
        super({
            name: "UsernameExistsException",
            $fault: "client",
            ...opts,
        });
        this.name = "UsernameExistsException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UsernameExistsException.prototype);
    }
}
export class AliasExistsException extends __BaseException {
    constructor(opts) {
        super({
            name: "AliasExistsException",
            $fault: "client",
            ...opts,
        });
        this.name = "AliasExistsException";
        this.$fault = "client";
        Object.setPrototypeOf(this, AliasExistsException.prototype);
    }
}
export class InvalidUserPoolConfigurationException extends __BaseException {
    constructor(opts) {
        super({
            name: "InvalidUserPoolConfigurationException",
            $fault: "client",
            ...opts,
        });
        this.name = "InvalidUserPoolConfigurationException";
        this.$fault = "client";
        Object.setPrototypeOf(this, InvalidUserPoolConfigurationException.prototype);
    }
}
export const AuthFlowType = {
    ADMIN_NO_SRP_AUTH: "ADMIN_NO_SRP_AUTH",
    ADMIN_USER_PASSWORD_AUTH: "ADMIN_USER_PASSWORD_AUTH",
    CUSTOM_AUTH: "CUSTOM_AUTH",
    REFRESH_TOKEN: "REFRESH_TOKEN",
    REFRESH_TOKEN_AUTH: "REFRESH_TOKEN_AUTH",
    USER_PASSWORD_AUTH: "USER_PASSWORD_AUTH",
    USER_SRP_AUTH: "USER_SRP_AUTH",
};
export const ChallengeNameType = {
    ADMIN_NO_SRP_AUTH: "ADMIN_NO_SRP_AUTH",
    CUSTOM_CHALLENGE: "CUSTOM_CHALLENGE",
    DEVICE_PASSWORD_VERIFIER: "DEVICE_PASSWORD_VERIFIER",
    DEVICE_SRP_AUTH: "DEVICE_SRP_AUTH",
    MFA_SETUP: "MFA_SETUP",
    NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
    PASSWORD_VERIFIER: "PASSWORD_VERIFIER",
    SELECT_MFA_TYPE: "SELECT_MFA_TYPE",
    SMS_MFA: "SMS_MFA",
    SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
};
export class MFAMethodNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "MFAMethodNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "MFAMethodNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, MFAMethodNotFoundException.prototype);
    }
}
export class PasswordResetRequiredException extends __BaseException {
    constructor(opts) {
        super({
            name: "PasswordResetRequiredException",
            $fault: "client",
            ...opts,
        });
        this.name = "PasswordResetRequiredException";
        this.$fault = "client";
        Object.setPrototypeOf(this, PasswordResetRequiredException.prototype);
    }
}
export class UserNotConfirmedException extends __BaseException {
    constructor(opts) {
        super({
            name: "UserNotConfirmedException",
            $fault: "client",
            ...opts,
        });
        this.name = "UserNotConfirmedException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UserNotConfirmedException.prototype);
    }
}
export const ChallengeName = {
    Mfa: "Mfa",
    Password: "Password",
};
export const ChallengeResponse = {
    Failure: "Failure",
    Success: "Success",
};
export const FeedbackValueType = {
    INVALID: "Invalid",
    VALID: "Valid",
};
export const EventResponseType = {
    Fail: "Fail",
    InProgress: "InProgress",
    Pass: "Pass",
};
export const RiskDecisionType = {
    AccountTakeover: "AccountTakeover",
    Block: "Block",
    NoRisk: "NoRisk",
};
export const RiskLevelType = {
    High: "High",
    Low: "Low",
    Medium: "Medium",
};
export const EventType = {
    ForgotPassword: "ForgotPassword",
    PasswordChange: "PasswordChange",
    ResendCode: "ResendCode",
    SignIn: "SignIn",
    SignUp: "SignUp",
};
export class UserPoolAddOnNotEnabledException extends __BaseException {
    constructor(opts) {
        super({
            name: "UserPoolAddOnNotEnabledException",
            $fault: "client",
            ...opts,
        });
        this.name = "UserPoolAddOnNotEnabledException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UserPoolAddOnNotEnabledException.prototype);
    }
}
export class InvalidEmailRoleAccessPolicyException extends __BaseException {
    constructor(opts) {
        super({
            name: "InvalidEmailRoleAccessPolicyException",
            $fault: "client",
            ...opts,
        });
        this.name = "InvalidEmailRoleAccessPolicyException";
        this.$fault = "client";
        Object.setPrototypeOf(this, InvalidEmailRoleAccessPolicyException.prototype);
    }
}
export class CodeMismatchException extends __BaseException {
    constructor(opts) {
        super({
            name: "CodeMismatchException",
            $fault: "client",
            ...opts,
        });
        this.name = "CodeMismatchException";
        this.$fault = "client";
        Object.setPrototypeOf(this, CodeMismatchException.prototype);
    }
}
export class ExpiredCodeException extends __BaseException {
    constructor(opts) {
        super({
            name: "ExpiredCodeException",
            $fault: "client",
            ...opts,
        });
        this.name = "ExpiredCodeException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ExpiredCodeException.prototype);
    }
}
export class PasswordHistoryPolicyViolationException extends __BaseException {
    constructor(opts) {
        super({
            name: "PasswordHistoryPolicyViolationException",
            $fault: "client",
            ...opts,
        });
        this.name = "PasswordHistoryPolicyViolationException";
        this.$fault = "client";
        Object.setPrototypeOf(this, PasswordHistoryPolicyViolationException.prototype);
    }
}
export class SoftwareTokenMFANotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "SoftwareTokenMFANotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "SoftwareTokenMFANotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, SoftwareTokenMFANotFoundException.prototype);
    }
}
export const DeviceRememberedStatusType = {
    NOT_REMEMBERED: "not_remembered",
    REMEMBERED: "remembered",
};
export const AdvancedSecurityEnabledModeType = {
    AUDIT: "AUDIT",
    ENFORCED: "ENFORCED",
};
export const AdvancedSecurityModeType = {
    AUDIT: "AUDIT",
    ENFORCED: "ENFORCED",
    OFF: "OFF",
};
export const AliasAttributeType = {
    EMAIL: "email",
    PHONE_NUMBER: "phone_number",
    PREFERRED_USERNAME: "preferred_username",
};
export class ConcurrentModificationException extends __BaseException {
    constructor(opts) {
        super({
            name: "ConcurrentModificationException",
            $fault: "client",
            ...opts,
        });
        this.name = "ConcurrentModificationException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ConcurrentModificationException.prototype);
    }
}
export class ForbiddenException extends __BaseException {
    constructor(opts) {
        super({
            name: "ForbiddenException",
            $fault: "client",
            ...opts,
        });
        this.name = "ForbiddenException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ForbiddenException.prototype);
    }
}
export const VerifiedAttributeType = {
    EMAIL: "email",
    PHONE_NUMBER: "phone_number",
};
export class GroupExistsException extends __BaseException {
    constructor(opts) {
        super({
            name: "GroupExistsException",
            $fault: "client",
            ...opts,
        });
        this.name = "GroupExistsException";
        this.$fault = "client";
        Object.setPrototypeOf(this, GroupExistsException.prototype);
    }
}
export const IdentityProviderTypeType = {
    Facebook: "Facebook",
    Google: "Google",
    LoginWithAmazon: "LoginWithAmazon",
    OIDC: "OIDC",
    SAML: "SAML",
    SignInWithApple: "SignInWithApple",
};
export class DuplicateProviderException extends __BaseException {
    constructor(opts) {
        super({
            name: "DuplicateProviderException",
            $fault: "client",
            ...opts,
        });
        this.name = "DuplicateProviderException";
        this.$fault = "client";
        Object.setPrototypeOf(this, DuplicateProviderException.prototype);
    }
}
export const UserImportJobStatusType = {
    Created: "Created",
    Expired: "Expired",
    Failed: "Failed",
    InProgress: "InProgress",
    Pending: "Pending",
    Stopped: "Stopped",
    Stopping: "Stopping",
    Succeeded: "Succeeded",
};
export const DeletionProtectionType = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};
export const EmailSendingAccountType = {
    COGNITO_DEFAULT: "COGNITO_DEFAULT",
    DEVELOPER: "DEVELOPER",
};
export const CustomEmailSenderLambdaVersionType = {
    V1_0: "V1_0",
};
export const CustomSMSSenderLambdaVersionType = {
    V1_0: "V1_0",
};
export const PreTokenGenerationLambdaVersionType = {
    V1_0: "V1_0",
    V2_0: "V2_0",
};
export const UserPoolMfaType = {
    OFF: "OFF",
    ON: "ON",
    OPTIONAL: "OPTIONAL",
};
export const UsernameAttributeType = {
    EMAIL: "email",
    PHONE_NUMBER: "phone_number",
};
export const DefaultEmailOptionType = {
    CONFIRM_WITH_CODE: "CONFIRM_WITH_CODE",
    CONFIRM_WITH_LINK: "CONFIRM_WITH_LINK",
};
export const StatusType = {
    Disabled: "Disabled",
    Enabled: "Enabled",
};
export class UserPoolTaggingException extends __BaseException {
    constructor(opts) {
        super({
            name: "UserPoolTaggingException",
            $fault: "client",
            ...opts,
        });
        this.name = "UserPoolTaggingException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UserPoolTaggingException.prototype);
    }
}
export const OAuthFlowType = {
    client_credentials: "client_credentials",
    code: "code",
    implicit: "implicit",
};
export const ExplicitAuthFlowsType = {
    ADMIN_NO_SRP_AUTH: "ADMIN_NO_SRP_AUTH",
    ALLOW_ADMIN_USER_PASSWORD_AUTH: "ALLOW_ADMIN_USER_PASSWORD_AUTH",
    ALLOW_CUSTOM_AUTH: "ALLOW_CUSTOM_AUTH",
    ALLOW_REFRESH_TOKEN_AUTH: "ALLOW_REFRESH_TOKEN_AUTH",
    ALLOW_USER_PASSWORD_AUTH: "ALLOW_USER_PASSWORD_AUTH",
    ALLOW_USER_SRP_AUTH: "ALLOW_USER_SRP_AUTH",
    CUSTOM_AUTH_FLOW_ONLY: "CUSTOM_AUTH_FLOW_ONLY",
    USER_PASSWORD_AUTH: "USER_PASSWORD_AUTH",
};
export const PreventUserExistenceErrorTypes = {
    ENABLED: "ENABLED",
    LEGACY: "LEGACY",
};
export const TimeUnitsType = {
    DAYS: "days",
    HOURS: "hours",
    MINUTES: "minutes",
    SECONDS: "seconds",
};
export class InvalidOAuthFlowException extends __BaseException {
    constructor(opts) {
        super({
            name: "InvalidOAuthFlowException",
            $fault: "client",
            ...opts,
        });
        this.name = "InvalidOAuthFlowException";
        this.$fault = "client";
        Object.setPrototypeOf(this, InvalidOAuthFlowException.prototype);
    }
}
export class ScopeDoesNotExistException extends __BaseException {
    constructor(opts) {
        super({
            name: "ScopeDoesNotExistException",
            $fault: "client",
            ...opts,
        });
        this.name = "ScopeDoesNotExistException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ScopeDoesNotExistException.prototype);
    }
}
export class UnsupportedIdentityProviderException extends __BaseException {
    constructor(opts) {
        super({
            name: "UnsupportedIdentityProviderException",
            $fault: "client",
            ...opts,
        });
        this.name = "UnsupportedIdentityProviderException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UnsupportedIdentityProviderException.prototype);
    }
}
export const CompromisedCredentialsEventActionType = {
    BLOCK: "BLOCK",
    NO_ACTION: "NO_ACTION",
};
export const EventFilterType = {
    PASSWORD_CHANGE: "PASSWORD_CHANGE",
    SIGN_IN: "SIGN_IN",
    SIGN_UP: "SIGN_UP",
};
export const DomainStatusType = {
    ACTIVE: "ACTIVE",
    CREATING: "CREATING",
    DELETING: "DELETING",
    FAILED: "FAILED",
    UPDATING: "UPDATING",
};
export const EventSourceName = {
    USER_AUTH_EVENTS: "userAuthEvents",
    USER_NOTIFICATION: "userNotification",
};
export const LogLevel = {
    ERROR: "ERROR",
    INFO: "INFO",
};
export const AdminAddUserToGroupRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminConfirmSignUpRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AttributeTypeFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Value && { Value: SENSITIVE_STRING }),
});
export const AdminCreateUserRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
    ...(obj.UserAttributes && {
        UserAttributes: obj.UserAttributes.map((item) => AttributeTypeFilterSensitiveLog(item)),
    }),
    ...(obj.ValidationData && {
        ValidationData: obj.ValidationData.map((item) => AttributeTypeFilterSensitiveLog(item)),
    }),
    ...(obj.TemporaryPassword && { TemporaryPassword: SENSITIVE_STRING }),
});
export const UserTypeFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
    ...(obj.Attributes && { Attributes: obj.Attributes.map((item) => AttributeTypeFilterSensitiveLog(item)) }),
});
export const AdminCreateUserResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.User && { User: UserTypeFilterSensitiveLog(obj.User) }),
});
export const AdminDeleteUserRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminDeleteUserAttributesRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminDisableUserRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminEnableUserRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminForgetDeviceRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminGetDeviceRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const DeviceTypeFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.DeviceAttributes && {
        DeviceAttributes: obj.DeviceAttributes.map((item) => AttributeTypeFilterSensitiveLog(item)),
    }),
});
export const AdminGetDeviceResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Device && { Device: DeviceTypeFilterSensitiveLog(obj.Device) }),
});
export const AdminGetUserRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminGetUserResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
    ...(obj.UserAttributes && {
        UserAttributes: obj.UserAttributes.map((item) => AttributeTypeFilterSensitiveLog(item)),
    }),
});
export const AdminInitiateAuthRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
    ...(obj.AuthParameters && { AuthParameters: SENSITIVE_STRING }),
});
export const AuthenticationResultTypeFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
    ...(obj.RefreshToken && { RefreshToken: SENSITIVE_STRING }),
    ...(obj.IdToken && { IdToken: SENSITIVE_STRING }),
});
export const AdminInitiateAuthResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Session && { Session: SENSITIVE_STRING }),
    ...(obj.AuthenticationResult && {
        AuthenticationResult: AuthenticationResultTypeFilterSensitiveLog(obj.AuthenticationResult),
    }),
});
export const AdminListDevicesRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminListDevicesResponseFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const AdminListGroupsForUserRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminListUserAuthEventsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminRemoveUserFromGroupRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminResetUserPasswordRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminRespondToAuthChallengeRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
    ...(obj.ChallengeResponses && { ChallengeResponses: SENSITIVE_STRING }),
    ...(obj.Session && { Session: SENSITIVE_STRING }),
});
export const AdminRespondToAuthChallengeResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Session && { Session: SENSITIVE_STRING }),
    ...(obj.AuthenticationResult && {
        AuthenticationResult: AuthenticationResultTypeFilterSensitiveLog(obj.AuthenticationResult),
    }),
});
export const AdminSetUserMFAPreferenceRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminSetUserPasswordRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
    ...(obj.Password && { Password: SENSITIVE_STRING }),
});
export const AdminSetUserSettingsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminUpdateAuthEventFeedbackRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminUpdateDeviceStatusRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AdminUpdateUserAttributesRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
    ...(obj.UserAttributes && {
        UserAttributes: obj.UserAttributes.map((item) => AttributeTypeFilterSensitiveLog(item)),
    }),
});
export const AdminUserGlobalSignOutRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const AssociateSoftwareTokenRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
    ...(obj.Session && { Session: SENSITIVE_STRING }),
});
export const AssociateSoftwareTokenResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.SecretCode && { SecretCode: SENSITIVE_STRING }),
    ...(obj.Session && { Session: SENSITIVE_STRING }),
});
export const ChangePasswordRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.PreviousPassword && { PreviousPassword: SENSITIVE_STRING }),
    ...(obj.ProposedPassword && { ProposedPassword: SENSITIVE_STRING }),
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
});
export const ConfirmDeviceRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
});
export const UserContextDataTypeFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const ConfirmForgotPasswordRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
    ...(obj.SecretHash && { SecretHash: SENSITIVE_STRING }),
    ...(obj.Username && { Username: SENSITIVE_STRING }),
    ...(obj.Password && { Password: SENSITIVE_STRING }),
    ...(obj.UserContextData && { UserContextData: SENSITIVE_STRING }),
});
export const ConfirmSignUpRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
    ...(obj.SecretHash && { SecretHash: SENSITIVE_STRING }),
    ...(obj.Username && { Username: SENSITIVE_STRING }),
    ...(obj.UserContextData && { UserContextData: SENSITIVE_STRING }),
});
export const UserPoolClientTypeFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
    ...(obj.ClientSecret && { ClientSecret: SENSITIVE_STRING }),
});
export const CreateUserPoolClientResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.UserPoolClient && { UserPoolClient: UserPoolClientTypeFilterSensitiveLog(obj.UserPoolClient) }),
});
export const DeleteUserRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
});
export const DeleteUserAttributesRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
});
export const DeleteUserPoolClientRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
});
export const DescribeRiskConfigurationRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
});
export const RiskConfigurationTypeFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
});
export const DescribeRiskConfigurationResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.RiskConfiguration && { RiskConfiguration: RiskConfigurationTypeFilterSensitiveLog(obj.RiskConfiguration) }),
});
export const DescribeUserPoolClientRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
});
export const DescribeUserPoolClientResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.UserPoolClient && { UserPoolClient: UserPoolClientTypeFilterSensitiveLog(obj.UserPoolClient) }),
});
export const ForgetDeviceRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
});
export const ForgotPasswordRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
    ...(obj.SecretHash && { SecretHash: SENSITIVE_STRING }),
    ...(obj.UserContextData && { UserContextData: SENSITIVE_STRING }),
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const GetDeviceRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
});
export const GetDeviceResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Device && { Device: DeviceTypeFilterSensitiveLog(obj.Device) }),
});
export const GetUICustomizationRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
});
export const UICustomizationTypeFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
});
export const GetUICustomizationResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.UICustomization && { UICustomization: UICustomizationTypeFilterSensitiveLog(obj.UICustomization) }),
});
export const GetUserRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
});
export const GetUserResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Username && { Username: SENSITIVE_STRING }),
    ...(obj.UserAttributes && {
        UserAttributes: obj.UserAttributes.map((item) => AttributeTypeFilterSensitiveLog(item)),
    }),
});
export const GetUserAttributeVerificationCodeRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
});
export const GlobalSignOutRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
});
export const InitiateAuthRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AuthParameters && { AuthParameters: SENSITIVE_STRING }),
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
    ...(obj.UserContextData && { UserContextData: SENSITIVE_STRING }),
});
export const InitiateAuthResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Session && { Session: SENSITIVE_STRING }),
    ...(obj.AuthenticationResult && {
        AuthenticationResult: AuthenticationResultTypeFilterSensitiveLog(obj.AuthenticationResult),
    }),
});
export const ListDevicesRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.AccessToken && { AccessToken: SENSITIVE_STRING }),
});
export const ListDevicesResponseFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const UserPoolClientDescriptionFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
});
export const ListUserPoolClientsResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.UserPoolClients && {
        UserPoolClients: obj.UserPoolClients.map((item) => UserPoolClientDescriptionFilterSensitiveLog(item)),
    }),
});
export const ListUsersResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Users && { Users: obj.Users.map((item) => UserTypeFilterSensitiveLog(item)) }),
});
export const ListUsersInGroupResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Users && { Users: obj.Users.map((item) => UserTypeFilterSensitiveLog(item)) }),
});
export const ResendConfirmationCodeRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
    ...(obj.SecretHash && { SecretHash: SENSITIVE_STRING }),
    ...(obj.UserContextData && { UserContextData: SENSITIVE_STRING }),
    ...(obj.Username && { Username: SENSITIVE_STRING }),
});
export const RespondToAuthChallengeRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
    ...(obj.Session && { Session: SENSITIVE_STRING }),
    ...(obj.ChallengeResponses && { ChallengeResponses: SENSITIVE_STRING }),
    ...(obj.UserContextData && { UserContextData: SENSITIVE_STRING }),
});
export const RespondToAuthChallengeResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Session && { Session: SENSITIVE_STRING }),
    ...(obj.AuthenticationResult && {
        AuthenticationResult: AuthenticationResultTypeFilterSensitiveLog(obj.AuthenticationResult),
    }),
});
export const RevokeTokenRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Token && { Token: SENSITIVE_STRING }),
    ...(obj.ClientId && { ClientId: SENSITIVE_STRING }),
    ...(obj.ClientSecret && { ClientSecret: SENSITIVE_STRING }),
});
